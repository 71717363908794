import moment from "moment";
import { HiOutlineIdentification } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./styles.scss";

interface PointProps {
  cdPontoIntegracaoSumario?: number;
  cdPontoIntegracaoSumarioCliente?: number;
  dhUltimoIntegrado?: Date;
  cdEmpresaPontoIntegracao: string;
  nmNome: string;
  dhExecucao: Date;
  nrErro?: number;
  nrAguardando?: number;
  nrSucesso?: number;
  dsObservacao?: string;
  snRegistroIntegrado?: String;
  snDetalheSucesso: string;
  snDetalheAguardando: string;
  nrDiasAnalise: number;
}

export function CarouselItem(props: PointProps) {
  function Status() {
    const successNumber = Number(props.nrSucesso);
    const waitingNumber = Number(props.nrAguardando);
    const errorNumber = Number(props.nrErro);

    const navigate = useNavigate();

    function navigationError(tpStatus?: string) {
      sessionStorage.setItem(
        "cdEmpresaPontoIntegracao",
        props.cdEmpresaPontoIntegracao
      );
      if (sessionStorage.getItem("snNovaInterface") === "S") {
        navigate(`/Detalhes`);
      } else {
        navigate(`/DetalhesAntigo/${tpStatus}`);
      }
    }

    // Sucesso
    if (errorNumber === 0 && waitingNumber === 0) {
      return (
        <>
          <div className="infos">
            <p>
              <i>
                <HiOutlineIdentification />
              </i>
              <div>
                <h5>{props.nmNome}</h5>
              </div>
            </p>
            <div className="status">
              <p className="success">Sucesso: {props.nrSucesso}</p>
              <p className="waiting">Aguardando: {waitingNumber}</p>
              <p className="error">Erro: {errorNumber}</p>
            </div>
          </div>
          <div
            className="circle success-circle"
            onClick={() => navigationError()}
          >
            <div className="num">{successNumber}</div>
          </div>
        </>
      );
    } else if (waitingNumber !== 0 && errorNumber === 0) {
      return (
        <>
          <div className="infos">
            <p>
              <i>
                <HiOutlineIdentification />
              </i>
              <h5>{props.nmNome}</h5>
            </p>

            <div className="status">
              <p className="success">Sucesso: {props.nrSucesso}</p>
              <p className="waiting">Aguardando: {waitingNumber}</p>
              <p className="error">Erro: {errorNumber}</p>
            </div>
          </div>

          <div
            className="circle waiting-circle"
            onClick={() => navigationError()}
          >
            <div className="num">{waitingNumber}</div>
          </div>
        </>
      );
    } else if (waitingNumber !== 0 && errorNumber === 0) {
      return (
        <>
          <div className="infos">
            <p>
              <i>
                <HiOutlineIdentification />
              </i>
              <h5>{props.nmNome}</h5>
            </p>

            <div className="status">
              <p className="success">Sucesso: {props.nrSucesso}</p>
              <p className="waiting">Aguardando: {waitingNumber}</p>
              <p className="error">Erro: {errorNumber}</p>
            </div>
          </div>

          <div
            className="circle waiting-circle"
            onClick={() => navigationError()}
          >
            <div className="num">{waitingNumber}</div>
          </div>
        </>
      );
      // Aguardando
    } else if (waitingNumber !== 0) {
      return (
        <>
          <div className="infos">
            <p>
              <i>
                <HiOutlineIdentification />
              </i>
              <h5>{props.nmNome}</h5>
            </p>
            <div className="status">
              <p className="success">Sucesso: {props.nrSucesso}</p>
              <p className="waiting">Aguardando: {waitingNumber}</p>
              <p className="error">Erro: {errorNumber}</p>
            </div>
          </div>
          <div
            className="circle waiting-circle"
            onClick={() => navigationError()}
          >
            <div className="num">{waitingNumber}</div>
          </div>
        </>
      );
      // Aguardando

      //erro
    } else if (waitingNumber === 0 && errorNumber !== 0) {
      return (
        <>
          <div className="infos">
            <p>
              <i>
                <HiOutlineIdentification />
              </i>
              <h5>{props.nmNome}</h5>
            </p>

            <div className="status">
              <div className="letter-hover">
                <p className="success">Sucesso: {props.nrSucesso}</p>
                <p className="waiting">Aguardando: {waitingNumber}</p>
                <p className="error">Erro: {errorNumber}</p>
              </div>
            </div>
          </div>

          <div
            className="circle error-circle"
            onClick={() => navigationError("Erro")}
          >
            <div className="num">{errorNumber}</div>
          </div>
        </>
      );
    } else {
      return;
    }
  }

  //Se o status for antigo retorna esse cara aqui :) (boa sorte pra você que vai mexer nisso no futuro)
  function StatusAntigo() {
    const successNumber = Number(props.nrSucesso);
    const waitingNumber = Number(props.nrAguardando);
    const errorNumber = Number(props.nrErro);

    const snSucesso = sessionStorage.getItem("snDetalheSucesso");
    const snAguardando = sessionStorage.getItem("snDetalheAguardando");

    const navigate = useNavigate();

    function navigationError(tpStatus?: string) {
      sessionStorage.setItem(
        "cdEmpresaPontoIntegracao",
        props.cdEmpresaPontoIntegracao
      );
      navigate(`/DetalhesAntigo/${tpStatus}`);
    }

    // Sucesso
    if (errorNumber === 0 && waitingNumber === 0) {
      return (
        <>
          <div className="infos">
            <p>
              <i>
                <HiOutlineIdentification />
              </i>
              <div>
                <h5>{props.nmNome}</h5>
              </div>
            </p>
            <div className="status">
              <p
                className="success"
                onClick={() => {
                  snSucesso === "S" && navigationError("Sucesso");
                }}
                style={
                  snSucesso === "S"
                    ? { cursor: "pointer" }
                    : { cursor: "default" }
                }
              >
                Sucesso: {props.nrSucesso}
              </p>
              <p
                className="waiting"
                onClick={() => {
                  snAguardando === "S" && navigationError("Aguardando");
                }}
                style={
                  snAguardando === "S"
                    ? { cursor: "pointer" }
                    : { cursor: "default" }
                }
              >
                Aguardando: {waitingNumber}
              </p>
              <p
                className="error"
                onClick={() => navigationError("Erro")}
                style={{ cursor: "pointer" }}
              >
                Erro: {errorNumber}
              </p>
            </div>
          </div>
          <div
            className="circle success-circle"
            onClick={() => {
              snSucesso === "S" && navigationError("Sucesso");
            }}
          >
            <div className="num">{successNumber}</div>
          </div>
        </>
      );
    } else if (waitingNumber !== 0 && errorNumber === 0) {
      return (
        <>
          <div className="infos">
            <p>
              <i>
                <HiOutlineIdentification />
              </i>
              <h5>{props.nmNome}</h5>
            </p>

            <div className="status">
              <p
                className="success"
                onClick={() => {
                  snSucesso === "S" && navigationError("Sucesso");
                }}
                style={
                  snSucesso === "S"
                    ? { cursor: "pointer" }
                    : { cursor: "default" }
                }
              >
                Sucesso: {props.nrSucesso}
              </p>
              <p
                className="waiting"
                onClick={() => {
                  snAguardando === "S" && navigationError("Aguardando");
                }}
                style={
                  snAguardando === "S"
                    ? { cursor: "pointer" }
                    : { cursor: "default" }
                }
              >
                Aguardando: {waitingNumber}
              </p>
              <p
                className="error"
                onClick={() => navigationError("Erro")}
                style={{ cursor: "pointer" }}
              >
                Erro: {errorNumber}
              </p>
            </div>
          </div>

          <div
            className="circle waiting-circle"
            onClick={() => navigationError("Erro")}
          >
            <div className="num">{waitingNumber}</div>
          </div>
        </>
      );
    } else if (waitingNumber !== 0 && errorNumber === 0) {
      return (
        <>
          <div className="infos">
            <p>
              <i>
                <HiOutlineIdentification />
              </i>
              <h5>{props.nmNome}</h5>
            </p>

            <div className="status">
              <p
                className="success"
                onClick={() => {
                  snSucesso === "S" && navigationError("Sucesso");
                }}
                style={
                  snSucesso === "S"
                    ? { cursor: "pointer" }
                    : { cursor: "default" }
                }
              >
                Sucesso: {props.nrSucesso}
              </p>
              <p
                className="waiting"
                onClick={() => {
                  snAguardando === "S" && navigationError("Aguardando");
                }}
                style={
                  snAguardando === "S"
                    ? { cursor: "pointer" }
                    : { cursor: "default" }
                }
              >
                Aguardando: {waitingNumber}
              </p>
              <p
                className="error"
                onClick={() => navigationError("Erro")}
                style={{ cursor: "pointer" }}
              >
                Erro: {errorNumber}
              </p>
            </div>
          </div>

          <div
            className="circle waiting-circle"
            onClick={() => navigationError("Erro")}
          >
            <div className="num">{waitingNumber}</div>
          </div>
        </>
      );
      // Aguardando
    } else if (waitingNumber !== 0) {
      return (
        <>
          <div className="infos">
            <p>
              <i>
                <HiOutlineIdentification />
              </i>
              <h5>{props.nmNome}</h5>
            </p>
            <div className="status">
              <p
                className="success"
                onClick={() => {
                  snSucesso === "S" && navigationError("Sucesso");
                }}
                style={
                  snSucesso === "S"
                    ? { cursor: "pointer" }
                    : { cursor: "default" }
                }
              >
                Sucesso: {props.nrSucesso}
              </p>
              <p
                className="waiting"
                onClick={() => {
                  snAguardando === "S" && navigationError("Aguardando");
                }}
                style={
                  snAguardando === "S"
                    ? { cursor: "pointer" }
                    : { cursor: "default" }
                }
              >
                Aguardando: {waitingNumber}
              </p>
              <p
                className="error"
                onClick={() => navigationError("Erro")}
                style={{ cursor: "pointer" }}
              >
                Erro: {errorNumber}
              </p>
            </div>
          </div>
          <div
            className="circle waiting-circle"
            onClick={() => navigationError("Erro")}
          >
            <div className="num">{waitingNumber}</div>
          </div>
        </>
      );
      // Aguardando

      //erro
    } else if (waitingNumber === 0 && errorNumber !== 0) {
      return (
        <>
          <div className="infos">
            <p>
              <i>
                <HiOutlineIdentification />
              </i>
              <h5>{props.nmNome}</h5>
            </p>

            <div className="status">
              <div className="letter-hover">
                <p
                  className="success"
                  onClick={() => {
                    snSucesso === "S" && navigationError("Sucesso");
                  }}
                  style={
                    snSucesso === "S"
                      ? { cursor: "pointer" }
                      : { cursor: "default" }
                  }
                >
                  Sucesso: {props.nrSucesso}
                </p>
                <p
                  className="waiting"
                  onClick={() => {
                    snAguardando === "S" && navigationError("Aguardando");
                  }}
                >
                  Aguardando: {waitingNumber}
                </p>
                <p className="error" onClick={() => navigationError("Erro")}>
                  Erro: {errorNumber}
                </p>
              </div>
            </div>
          </div>

          <div
            className="circle error-circle"
            onClick={() => navigationError("Erro")}
          >
            <div className="num">{errorNumber}</div>
          </div>
        </>
      );
    } else {
      return;
    }
  }

  //aqui faz a seleção de qual tipo de card vai ser renderizado em tela
  return (
    <div className="swiper-item">
      <ToastContainer />
      <div className="col">
        <div className="info-content">
          {sessionStorage.getItem("snNovaInterface") === "S"
            ? Status()
            : StatusAntigo()}
        </div>
        <p className="update-at">
          Atualização: {moment(props.dhExecucao).format("DD/MM/YY HH:mm")}
        </p>
      </div>
    </div>
  );
}
