import { AiOutlinePieChart } from "react-icons/ai";
import { BiRocket } from "react-icons/bi";
import { ImExit } from "react-icons/im";
import { RiWechatLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { useContext, useState } from "react";
import logo from "../../assets/favicon.png";
import "./styles.scss";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { AuthContext } from "../../contexts/Auth/ProviderContext";

export function SideBar(props: SvgIconProps) {
  const auth = useContext(AuthContext);
  const tipoPessoa = sessionStorage.getItem("tipoPessoa");
  const [showMorePages, setShowMorePages] = useState<boolean>(false);
  const logout = async () => {
    await auth.signout();
  };
  console.log(auth.telasCustom);
  if (tipoPessoa === "2") {
    return (
      <div className="sidebar">
        <div className="logo">
          <div data-tip="DataIntegra">
            <img src={logo} alt="DataIntegra" />
            <ReactTooltip place="right" type="light" effect="solid" />
          </div>
        </div>
        <nav className="nav-flex-column">
          <Link to="/">
            <i>
              <p data-tip="Dashboard">
                <SvgIcon {...props}>
                  <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
                </SvgIcon>
              </p>
              <ReactTooltip
                place="right"
                type="light"
                effect="solid"
                className="tooltip"
              />
            </i>
          </Link>

          <Link to="/Metricas">
            <i>
              <p data-tip="Métricas">
                <AiOutlinePieChart size={20} />
              </p>
              <ReactTooltip place="right" type="light" effect="solid" />
            </i>
          </Link>
          {/* <Link to="">
            <i>
              <p data-tip="Configurações">
                <PiGear size={20} />
              </p>
              <ReactTooltip place="right" type="light" effect="solid" />
            </i>
          </Link>
          <a onMouseEnter={() => setShowMorePages(true)}>
            <i>
              <p>
                <CgScreen size={20} />
              </p>
              {showMorePages && auth?.telasCustom?.length > 0 && (
                <div
                  className="more-itens"
                  onMouseLeave={() => setShowMorePages(false)}
                >
                  {auth?.telasCustom?.map((item) => {
                    return (
                      <>
                        <Link
                          key={item.cdTelaCustomPessoa}
                          to={`/${item.dsTelaCustom
                            .normalize("NFD")
                            .replaceAll(/[\u0300-\u036f]/g, "")
                            .replaceAll(" ", "")}
                            `}
                        >
                          <span>
                            <MdOutlineFolderOpen size={20} />
                            <p data-tip={item.dsTelaCustom}>
                              {item.dsTelaCustom.length > 12
                                ? item.dsTelaCustom.substring(0, 12) + "..."
                                : item.dsTelaCustom}
                            </p>
                          </span>
                        </Link>
                        <ReactTooltip
                          place="right"
                          type="light"
                          effect="solid"
                          className="tooltip"
                        />
                      </>
                    );
                  })}
                </div>
              )}
            </i>
          </a> */}
          <a
            href="https://paineldataintegra.atlassian.net/servicedesk/customer/portal/1"
            target="_blank"
            rel="noreferrer"
          >
            <i>
              <p data-tip="Ajuda & Suporte">
                <RiWechatLine size={20} />
              </p>
              <ReactTooltip place="right" type="light" effect="solid" />
            </i>
          </a>

          <Link to="/login" onClick={logout} className="sair">
            <p data-tip="Sair">
              <ImExit size={20} />
            </p>
          </Link>
          <ReactTooltip
            place="right"
            type="light"
            effect="solid"
            className="tooltip"
          />

          {/* 
        <Link to="/Configuracoes">
          <i>
            <p data-tip="Configurações">
              <VscTools />
            </p>
            <ReactTooltip place="right" type="light" effect="solid" />
          </i>
        </Link>
        */}
        </nav>
      </div>
    );
  } else {
    return (
      <div className="sidebar">
        <div className="logo">
          <div data-tip="DataIntegra">
            <img src={logo} alt="DataIntegra" />
            <ReactTooltip place="right" type="light" effect="solid" />
          </div>
        </div>
        <nav className="nav flex-column">
          <Link to="/">
            <i>
              <p data-tip="Dashboard">
                <BiRocket size={20} />
              </p>
              <ReactTooltip
                place="right"
                type="light"
                effect="solid"
                className="tooltip"
              />
            </i>
          </Link>

          <a
            href="https://paineldataintegra.atlassian.net/servicedesk/customer/portal/1"
            target="_blank"
            rel="noreferrer"
          >
            <i>
              <p data-tip="Ajuda & Suporte">
                <RiWechatLine size={20} />
              </p>
              <ReactTooltip place="right" type="light" effect="solid" />
            </i>
          </a>

          <Link to="/login" onClick={logout} className="sair">
            <p data-tip="Sair">
              <ImExit size={20} />
            </p>
          </Link>
          <ReactTooltip
            place="right"
            type="light"
            effect="solid"
            className="tooltip"
          />
        </nav>
      </div>
    );
  }
}
