import dayjs from "dayjs";
import { createContext, ReactNode, useEffect, useState } from "react";
import { api } from "../services/api";

interface MetricsContextData {
  points: Point[];
}

interface MetricsProviderProps {
  children: ReactNode;
}

interface Point {
  cdPontoIntegracaoSumario: number;
  cdEmpresaPontoIntegracao: number;
  empresaPontoIntegracao: {
    nmNome: string;
    cdEmpresaPontoIntegracao: string;
  };
  dhUltimoIntegrado: Date;
  dhExecucao: Date;
  nomeEmpresa: string;
  nrErro?: number;
  nrAguardando?: number;
  nrSucesso?: number;
  dsObservacao?: string;
  snRegistroIntegrado?: boolean;
  cdempresa: string;
  nrDiasAnalise?: number;
}

export const MetricsContext = createContext({} as MetricsContextData);

export function DataMetricsProvider({ children }: MetricsProviderProps) {
  const [points, setPoints] = useState<Point[]>([]);

  //const que armazena o valor da data completa, exemplo: 01/01/2023
  let dataHora = sessionStorage.getItem("day");
  //const que armazena o valor do mês, exemplo: 01
  let dataDia = sessionStorage.getItem("month");
  //const que armazena o valor do ano, exemplo: 2023
  let dataMes = sessionStorage.getItem("year");
  //const que armazena o valor da primeira data selecionada no calendário, exemplo: 01/01/2023
  let day1 = sessionStorage.getItem("day1");
  //const que armazena o valor da segunda data selecionada no calendário, exemplo: 01/01/2023
  let day2 = sessionStorage.getItem("day2");

  //se as const não tiverem valor, atribui o valor setado estáticamente no código
  if (
    dataHora == null ||
    dataDia == null ||
    dataMes == null ||
    day1 == null ||
    day2 == null
  ) {
    dataHora = dayjs().format("DD/MM/YYYY");
    sessionStorage.setItem("day", dataHora);

    dataDia = dayjs().format("DD/MM/YYYY");
    sessionStorage.setItem("month", dataDia);

    dataMes = dayjs().format("YYYY");
    sessionStorage.setItem("year", dataMes);

    day1 = dayjs().subtract(30, "day").format("DD/MM/YYYY");
    sessionStorage.setItem("day1", day1);

    day2 = dayjs().format("DD/MM/YYYY");
    sessionStorage.setItem("day2", day2);
  }

  useEffect(() => {
    setTimeout(getDataDash, 500);
  }, []);

  const getDataDash = async () => {
    setPoints([]);
    await api
      .get<Point[]>(
        "/pontoIntegrSumario/buscarPorUltimoIntegradoMultiempresa",
        {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setPoints(response.data);
      })
      .catch(function (err) {});
  };

  return (
    <MetricsContext.Provider
      value={{
        points,
      }}
    >
      {children}
    </MetricsContext.Provider>
  );
}
