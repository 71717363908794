import Calendar from "react-calendar";
interface Req {
  values;
  onViewChange;
  options: boolean;
  showNavigation: boolean;
  view: string;
  hideExtraYears: boolean;
  selectRange: boolean;
  maxDate: boolean;
}

export function CalendarBase(props: Req) {
  return (
    <Calendar
      onChange={props.values}
      onViewChange={props.onViewChange}
      view={props.view}
      showNavigation={props.showNavigation}
      maxDate={new Date()}
      selectRange={props.selectRange}
      hideExtraYears={props.hideExtraYears}
    />
  );
}
