import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import React, { useContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { ErrorFixModal } from "../../components/ErrorFixModal";
import { ErrorFixModal2 } from "../../components/ErrorFixModal2";
import { ErrorsContextAntigo } from "../../contexts/ErrorDetailsAntigo/ErrorContextAntigo";
import { api } from "../../services/api";

import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";
import { Header } from "../../components/Header";
import "./styles.scss";

export function ErrorDetailsAntigo() {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [openOcultar, setOpenOcultar] = React.useState(false);
  const [openReprocessar, setOpenReprocessar] = React.useState(false);
  const [identificador, setIdentificador] = React.useState("");
  const [cdIntegracao, setCdIntegracao] = React.useState("");
  const { integrationPoint, nrDiasAnalise, setLoadingDetails, loadingDetails } =
    useContext(ErrorsContextAntigo);
  const { errors } = useContext(ErrorsContextAntigo);
  const [idPonto, setIdPonto] = useState("");

  const [detalhesFlag, setDetalhesFlag] = useState();
  const [reprocessarFlag, setReprocessarFlag] = useState();
  const [ocultarFlag, setOcultarFlag] = useState();
  const [sugestaoFlag, setSugestaoFlag] = useState();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleClickOpenReprocess = (identificador, cdIntegracao) => {
    setIdentificador(identificador);
    setCdIntegracao(cdIntegracao);
    setIdPonto(idPonto);
    setOpenReprocessar(true);
  };

  const handleClickOpenOcultar = (identificador, cdIntegracao) => {
    setIdentificador(identificador);
    setCdIntegracao(cdIntegracao);
    setIdPonto(idPonto);
    setOpenOcultar(true);
  };

  const [indiceModal, setIndiceModal] = useState(null);

  const handleClick = (indice) => {
    setIndiceModal(indice);
  };

  const handleOkReprocessar = () => {
    reprocess();
    setOpenReprocessar(false);
    window.location.reload();
  };

  const handleOkOcultar = () => {
    ocultarIntegracao();
    setOpenOcultar(false);
    window.location.reload();
  };

  const handleClose = () => {
    setOpenReprocessar(false);
    setOpenOcultar(false);
  };

  const reprocess = async () => {
    setIdPonto(sessionStorage.getItem("cdEmpresaPontoIntegracao"));
    let cdIntegra = sessionStorage.getItem("cdEmpresaPontoIntegracao");

    api
      .post(
        `/empPontoIntegracao/reprocessarIntegracao/`,
        {},
        {
          headers: {
            token: sessionStorage.getItem("token"),
            cdEmpresaPontoIntegracao: cdIntegra,
            cdIntegracao: cdIntegracao,
          },
        }
      )
      .then((response) => {
        toast.success(response.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const ocultarIntegracao = async () => {
    setIdPonto(sessionStorage.getItem("cdEmpresaPontoIntegracao"));
    let cdIntegra = sessionStorage.getItem("cdEmpresaPontoIntegracao");
    api
      .post(
        `/empPontoIntegracao/ocultarRegistroIntegracao`,

        {},
        {
          headers: {
            token: sessionStorage.getItem("token"),
            cdEmpresaPontoIntegracao: cdIntegra,
            cdIntegracao: cdIntegracao,
          },
        }
      )
      .then((response) => {
        toast.success(response.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const CreateXLS = async () => {
    for (let i = 0; i < errors.length; i++) {
      let erros = errors[i].detalheErroDTOAdapter;

      let binaryWS = XLSX.utils.json_to_sheet(erros);
      var wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, binaryWS, "Binary values");

      XLSX.writeFile(wb, `${errors[i].nomeEmpresa}.XLSX`);
    }
  };

  return (
    <>
      <section>
        <ToastContainer />
        <Header
          message={"Dashboard/Detalhes"}
          dayValue={nrDiasAnalise}
          showAtualizacao={true}
        />
        <div className="error-content ">
          <div className="error-container">
            <h1>{integrationPoint}</h1>

            {loadingDetails === true && (
              <div className="divLoading">
                <h1 className="loading">Carregando...</h1>
              </div>
            )}

            {loadingDetails === false && (
              <Button onClick={CreateXLS}>
                <img
                  src="https://icons.iconarchive.com/icons/carlosjj/microsoft-office-2013/128/Excel-icon.png"
                  width="30"
                  height="30"
                  alt=""
                />
              </Button>
            )}

            {errors?.map((pontoIntegracao) => (
              <div>
                <h3 className="topic-title">
                  {pontoIntegracao.codigoEmpresa} -{" "}
                  {pontoIntegracao.nomeEmpresa} [
                  {pontoIntegracao.detalhamentoDTOAdapter.length}]
                </h3>

                <div className="divider"></div>
                <div className="error-content">
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                    sx={{
                      background: "none",
                      color: "white",
                      boxShadow: "none",
                      marginTop: "-0.6rem",
                      width: "100%",
                    }}
                  >
                    <AccordionDetails>
                      <Typography>
                        <div className="accordion-content">
                          <table className="th_deg">
                            <tr>
                              <th className="IDErroHeader">ID</th>
                              <th className="DHGeradoErroHeader">DH gerado</th>
                              <th className="DHProcessadoErroHeader">
                                DH processado
                              </th>
                              <th className="DescricaoErroHeader">Descrição</th>
                              <th className="DetalhesErroHeader">Ações</th>
                            </tr>
                            {pontoIntegracao.detalhamentoDTOAdapter.map(
                              (e, index) => (
                                <tr>
                                  <td className="IDErro">{e.identificador}</td>
                                  <td className="DHGeradoErro">
                                    {e.dataHoraGeradoFormat}
                                  </td>
                                  <td className="DHProcessadoErro">
                                    {e.dataHoraProcessadoFormat}
                                  </td>
                                  {e.descricaoErro != null ? (
                                    <td className="DescricaoErro">
                                      {`${e.descricaoErro.substring(0, 30)}...`}
                                    </td>
                                  ) : (
                                    <td className="DescricaoErro"></td>
                                  )}

                                  <td className="DetalhesErro">
                                    <div className="botoes">
                                      <button
                                        type="button"
                                        onMouseOver={() =>
                                          setDetalhesFlag(index)
                                        }
                                        onMouseOut={() => setDetalhesFlag(null)}
                                      >
                                        <ErrorFixModal {...e} />
                                      </button>
                                      {detalhesFlag === index && (
                                        <div className="detalhes-flag">
                                          <p>Detalhes</p>
                                        </div>
                                      )}

                                      <div>
                                        <Button
                                          onClick={() =>
                                            handleClickOpenReprocess(
                                              e.identificador,
                                              e.cdIntegracao
                                            )
                                          }
                                          onMouseOver={() =>
                                            setReprocessarFlag(index)
                                          }
                                          onMouseOut={() =>
                                            setReprocessarFlag(null)
                                          }
                                        >
                                          🔃
                                        </Button>

                                        <Dialog
                                          open={openReprocessar}
                                          onClose={handleClose}
                                          aria-labelledby="alert-dialog-title"
                                          aria-describedby="alert-dialog-description"
                                        >
                                          <DialogTitle
                                            id="responsive-dialog-title"
                                            style={{
                                              backgroundColor:
                                                "hsl(206, 100%, 12%)",
                                              color: "white",
                                              marginTop: "-2rem",
                                            }}
                                          >
                                            {pontoIntegracao.reprocessamento}
                                          </DialogTitle>

                                          <DialogContent
                                            style={{
                                              backgroundColor:
                                                "hsl(206, 100%, 12%)",
                                              paddingLeft: "2rem",
                                            }}
                                          >
                                            <DialogContentText id="alert-dialog-description">
                                              Deseja reprocessar o id:{" "}
                                              {identificador} ?
                                            </DialogContentText>
                                          </DialogContent>
                                          <DialogActions
                                            style={{
                                              backgroundColor:
                                                "hsl(206, 100%, 12%)",
                                              color: "white",
                                              marginTop: "-2rem",
                                            }}
                                          >
                                            <Button onClick={handleClose}>
                                              Não
                                            </Button>
                                            <Button
                                              onClick={handleOkReprocessar}
                                            >
                                              Sim
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                      {reprocessarFlag === index && (
                                        <div className="reprocessar-flag">
                                          <p>Reprocessar</p>
                                        </div>
                                      )}
                                      <div>
                                        <Button
                                          onClick={() =>
                                            handleClickOpenOcultar(
                                              e.identificador,
                                              e.cdIntegracao
                                            )
                                          }
                                          onMouseOver={() =>
                                            setOcultarFlag(index)
                                          }
                                          onMouseOut={() =>
                                            setOcultarFlag(null)
                                          }
                                        >
                                          ❌
                                        </Button>
                                        <Dialog
                                          open={openOcultar}
                                          onClose={handleClose}
                                          aria-labelledby="alert-dialog-title"
                                          aria-describedby="alert-dialog-description"
                                        >
                                          <DialogTitle
                                            id="responsive-dialog-title"
                                            style={{
                                              backgroundColor:
                                                "hsl(206, 100%, 12%)",
                                              color: "white",
                                              marginTop: "-2rem",
                                            }}
                                          >
                                            {pontoIntegracao.ocultarIntegracao}
                                          </DialogTitle>

                                          <DialogContent
                                            style={{
                                              backgroundColor:
                                                "hsl(206, 100%, 12%)",
                                              paddingLeft: "2rem",
                                            }}
                                          >
                                            <DialogContentText id="alert-dialog-description">
                                              Deseja ocultar o id:{" "}
                                              {identificador} ?
                                            </DialogContentText>
                                          </DialogContent>
                                          <DialogActions
                                            style={{
                                              backgroundColor:
                                                "hsl(206, 100%, 12%)",
                                              color: "white",
                                              marginTop: "-2rem",
                                            }}
                                          >
                                            <Button onClick={handleClose}>
                                              Não
                                            </Button>
                                            <Button onClick={handleOkOcultar}>
                                              Sim
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                      {ocultarFlag === index && (
                                        <div className="ocultar-flag">
                                          <p>Ocultar</p>
                                        </div>
                                      )}

                                      {""}
                                      <button
                                        type="button"
                                        onMouseOver={() =>
                                          setSugestaoFlag(index)
                                        }
                                        onMouseOut={() => setSugestaoFlag(null)}
                                      >
                                        <ErrorFixModal2 {...e} />
                                      </button>
                                      {sugestaoFlag === index && (
                                        <div className="sugestao-flag">
                                          <p>Sugestão</p>
                                        </div>
                                      )}
                                      {""}
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </table>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
