import { MdClose } from "react-icons/md";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import * as React from "react";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import "./styles.scss";

interface ErrorResponseProps {
  identificador: number;
  codigoEmpresa: number;
  nomeEmpresa: string;
  dataHoraGerado: string;
  dataHoraProcessado: string;
  dataHoraGeradoFormat: string;
  dataHoraProcessadoFormat: string;
  descricaoErro: string;
  dsErro: String;
  sugestaoErro: String;
}

export function ErrorFixModal2(props: ErrorResponseProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="modal-container">
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        style={{ border: "none" }}
      >
        <button>
          <HelpOutlineIcon />
        </button>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth={"lg"}
      >
        <DialogActions style={{ backgroundColor: "hsl(206, 100%, 12%)" }}>
          <Button
            autoFocus
            onClick={handleClose}
            style={{ fontSize: "1.2rem", marginRight: "-0.5rem" }}
          >
            <MdClose />
          </Button>
        </DialogActions>
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            backgroundColor: "hsl(206, 100%, 12%)",
            color: "white",
            marginTop: "-2rem",
          }}
        ></DialogTitle>
        <DialogContent
          style={{
            backgroundColor: "hsl(206, 100%, 12%)",
            paddingLeft: "2rem",
          }}
        >
          <DialogContentText>
            <h3>{"Sugestões para corrigir os erros:"} </h3>

            {props.sugestaoErro != null ? (
              <div
                style={{ padding: "1.7rem" }}
                dangerouslySetInnerHTML={{
                  __html: `<ul><li>${props.sugestaoErro.replaceAll(
                    ";",
                    "</li><br /><li>"
                  )}</ul>`,
                }}
              />
            ) : (
              <div
                style={{ padding: "1.7rem" }}
                dangerouslySetInnerHTML={{ __html: `` }}
              />
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
