import { AiOutlinePieChart } from "react-icons/ai";

import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement
);

interface Req {
  arrayGraficoArquitetura;
}

export function PieChart2(props: Req) {
  var valor = [];
  var descricao = [];
  var cores = [];

  var grafico = props.arrayGraficoArquitetura;

  valor = grafico?.map((item, index) => item.valor) || [];

  descricao = grafico?.map((item, index) => item.descricao) || [];

  cores = [
    "hsl(133, 67%, 65%)",
    "#E3A254",
    "hsl(0, 68%, 65%)",
    "#6969e2",
    "#69e2e2",
    "#CD853F",
    "#e1b74c",
    "#D2691E",
    "#F4A460",
    "#FFDEAD",
    "#F5DEB3",
    "#FA8072",
    "#FF7F50",
  ];

  var valoresPorcentagem;
  if (valor) {
    const soma = valor.reduce(function (acc, value) {
      return acc + value;
    });

    const porcentagem = valor.map((element) => {
      let i = (element / soma) * 100;
      return i;
    });
    valoresPorcentagem = porcentagem;
  }

  const dataPie = {
    datasets: [
      {
        data: valor,
        backgroundColor: cores,
        borderColor: cores,
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div className="text">
        <p>Total de Cliente por Arquitetura </p>
        <i>
          <AiOutlinePieChart />
          <p>{valor.length}</p>
        </i>
      </div>
      <div className="pie">
        <div>
          <Pie data={dataPie} style={{ maxHeight: "150px" }} />
        </div>
      </div>

      <div className="pie">
        <div className="infos-pie">
          {descricao.map((item, index) => (
            <h6 key={index}>
              <span style={{ backgroundColor: cores[index] }}>.</span> {item}: (
              {valoresPorcentagem[index].toFixed(1)})%
            </h6>
          ))}
        </div>
      </div>
    </>
  );
}
