import dayjs from "dayjs";
import { api } from "../../services/api";

export const getInfosChart = async () => {
  const response = await api.get("/grafico/listarGraficosParams", {
    headers: {
      token: sessionStorage.getItem("token"),
      dataGraficoPorHora:
        sessionStorage.getItem("day") || dayjs().format("DD/MM/YYYY"), //integração por dia
      mesGraficoPorDia:
        sessionStorage.getItem("month") || dayjs().format("DD/MM/YYYY"), //integração por mes
      anoGraficoPorMes:
        sessionStorage.getItem("year") || dayjs().format("YYYY"), //integração por ano
      dataFimGraficoPorPontoIntegr:
        sessionStorage.getItem("day2") || dayjs().format("DD/MM/YYYY"),
      dataInicioGraficoPorPontoIntegr:
        sessionStorage.getItem("day1") || dayjs().format("DD/MM/YYYY"),
    },
  });
  return response;
};
