import { AiOutlinePieChart } from "react-icons/ai";

import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement
);

interface Req {
  arrayPontoIntegracao;
}

export function PieChart(props: Req) {
  var valor = [];
  var descricao = [];
  var cores = [];
  var grafico = props.arrayPontoIntegracao;

  valor = grafico?.map((item, index) => item.valor) || [];
  descricao = grafico?.map((item, index) => item.descricao) || [];
  cores = [
    "hsl(0, 68%, 65%)",
    "#E3A254",
    "hsl(133, 67%, 65%)",
    "#e1b74c",
    "#69e2e2",
    "#CD853F",
    "#6969e2",
    "#D2691E",
    "#F4A460",
    "#FFDEAD",
    "#F5DEB3",
    "#FA8072",
    "#FF7F50",
  ];

  var valoresPorcentagem;
  if (valor.length !== 0) {
    const soma = valor.reduce(function (acc, value) {
      return acc + value;
    });

    const porcentagem = valor.map((element) => {
      let i = (element / soma) * 100;
      return i;
    });
    valoresPorcentagem = porcentagem;
  }

  const dataPie = {
    datasets: [
      {
        labels: descricao,
        data: valor,
        backgroundColor: cores,
        borderColor: cores,
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      <div className="text">
        <i>
          <AiOutlinePieChart />
          <p>{valor.length}</p>
        </i>
      </div>
      <div className="pie">
        {/* valor de options passado dentro do grafico temporariamente */}
        <div>
          <Pie
            data={dataPie}
            options={{
              plugins: {
                tooltip: {
                  enabled: true,
                  mode: "nearest",
                  callbacks: {
                    label: (tooltipItem) => {
                      // retorna a descrição e o valor correspondente ao tooltipItem
                      const label = descricao;
                      const value = valor;
                      const porcentagem = valoresPorcentagem;
                      return `${label[tooltipItem.dataIndex]}: ${
                        value[tooltipItem.dataIndex] +
                        " (" +
                        porcentagem[tooltipItem.dataIndex].toFixed(1) +
                        "%)"
                      }`;
                    },
                  },
                },
              },
            }}
            style={{ maxHeight: "150px" }}
          />
        </div>
      </div>
    </>
  );
}
