import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { api } from "../../services/api";

import "react-toastify/dist/ReactToastify.css";
import "./styleRecupera.css";

export const RecuperaSenha = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const enviaEmail = async (event: any) => {
    if (event.key === "Enter" || event.type === "click") {
      await api
        .post(
          "/pessoa/recuperarSenha",
          {},
          {
            headers: {
              dsEmail: email,
            },
          }
        )
        .then((resul) => {
          if (resul.status === 200) {
            toast.success("Email enviado com sucesso !");
            setTimeout(function () {
              navigate("/login");
            }, 3000);
          }
        })
        .catch(function (err) {
          const { data } = err.response;
          toast.error(data.message);
        });
    }
  };

  const voltar = () => {
    navigate("/login");
  };
  return (
    <>
      <ToastContainer />
      <div className="container-recupera">
        <div className="wrap-recupera">
          <div className="itens">
            <div className="wrap-input">
              <p className="txt-recupera">Recuperar senha</p>
              <input
                className={"email"}
                id="user"
                type="text"
                name="user"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                onKeyPress={enviaEmail}
              />
              <span className="focus-input" data-placeholder="Login"></span>
            </div>

            <div className="container-recupera-form-btn">
              <button
                className="recupera-form-btn"
                onClick={enviaEmail}
                onKeyPress={enviaEmail}
              >
                Recuperar
              </button>
            </div>
            <button className="voltar" onClick={voltar}>
              Voltar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
