import "./styleModalJson.scss";

interface ModalJsonProps {
  setOpenModalJson: any;
  jsonEnviado: string;
  respostaJson: string;
}
export function ModalJson(props: ModalJsonProps) {


  function handleclick(e: any) {
    if (e.target === e.currentTarget) {
      props.setOpenModalJson(false);
    }
  }
  return (
    <div className="modalJson-background" onClick={(e) => handleclick(e)}>
      <div className="modalJson-container" style={
        props.respostaJson !== "" ? {height: "80%"} : {height: "80%"}
      }>
        <div className="modalJson-separador">
          <h4>Envio: </h4>
          <button onClick={() => props.setOpenModalJson(false)}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          </button>
        </div>
        <div className="modalJson-jsonEnviado"
            style={
                props.respostaJson !== "" ?  {} : {height: "100%"}

            }
        >
          <pre>
            {JSON.stringify(
              JSON.parse(props.jsonEnviado.replace("Json enviado:", "")),
              null,
              2
            )}
          </pre>
        </div>
        {props.respostaJson !== "" ? (
          <>
            <div className="modalJson-separador">
              <h4>Resposta: </h4>
            </div>
            <div className="modalJson-respostaJson">
              <pre>
                {JSON.stringify(JSON.parse(props.respostaJson), null, 2)}
              </pre>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
