import Modal from "react-modal";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DataErrorsProvider } from "./contexts/ErrorContext";
import { DataErrorsProviderAntigo } from "./contexts/ErrorDetailsAntigo/ErrorContextAntigo";
import { DataProvider } from "./contexts/PointsContext";
import { Carousel } from "./pages/Dashboard";
import { UserLogin } from "./pages/Login";
import { Metrics } from "./pages/Metrics";
import { RecuperaSenha } from "./pages/RecuperaSenha";
import { ErrorDetails } from "./pages/errorDetails";
import { ErrorDetailsAntigo } from "./pages/errorDetailsAntigo";

import "./App.scss";
import NewTable from "./components/NewTable";
import { AuthProvider } from "./contexts/Auth/ProviderContext";
import { RequireAuth } from "./contexts/Auth/RequireAuth";
import { DataMetricsProvider } from "./contexts/MetricsContext";
import "./styles/global.scss";

Modal.setAppElement("#root");

function App() {
  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <>
            <Routes>
              <Route path="/login" element={<UserLogin />} />
              <Route path="/recuperaSenha" element={<RecuperaSenha />} />

              <Route
                path="/Detalhes"
                element={
                  <DataErrorsProvider>
                    <ErrorDetails />
                  </DataErrorsProvider>
                }
              />
              <Route
                path="/DetalhesAntigo/:tpStatus"
                element={
                  <DataErrorsProviderAntigo>
                    <ErrorDetailsAntigo />
                  </DataErrorsProviderAntigo>
                }
              />
              <Route
                path="/Metricas"
                element={
                  <RequireAuth>
                    <DataMetricsProvider>
                      <Metrics />
                    </DataMetricsProvider>
                  </RequireAuth>
                }
              />
              <Route
                path="/Faturamento"
                element={
                  <RequireAuth>
                    <NewTable />
                  </RequireAuth>
                }
              />
            </Routes>
          </>

          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <DataErrorsProvider>
                    <DataProvider>
                      <Carousel />
                    </DataProvider>
                  </DataErrorsProvider>
                </RequireAuth>
              }
            />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}

export default App;