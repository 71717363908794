import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { api } from "../services/api";


interface Point {
  cdPontoIntegracaoSumario: number;
  cdEmpresaPontoIntegracao: number;
  empresaPontoIntegracao: {
    nmNome: string;
    cdEmpresaPontoIntegracao: string;
    n;
    snDetalheSucesso: string,
    snDetalheAguardando: string,
  };
  dhUltimoIntegrado: Date;
  dhExecucao: Date;
  nomeEmpresa: string;
  nrErro?: number;
  nrAguardando?: number;
  nrSucesso?: number;
  dsObservacao?: string;
  snRegistroIntegrado?: boolean;
  cdempresa: string;
  nrDiasAnalise?: number;
  token: string;
}

type PointInput = Omit<
  Point,
  "cdPontoIntegracaoSumario" | "cdPontoIntegracaoSumarioCliente"
>;

interface ErrorResponseProps {
  identificador: number;
  cdIntegracao: number;
  codigoEmpresa: number;
  nomeEmpresa: string;
  dataHoraGerado: string;
  dataHoraProcessado: string;
  dataHoraGeradoFormat: string;
  dataHoraProcessadoFormat: string;
  descricaoErro: string;
  nrErro: string;
  qr_reprocessamento: string;
  qr_ocultar_registro: string;
}

interface PointsContextData {
  points: Point[];
  errors;
  loading;
  nomeEmpresa;
  nrDiasAnalise;
  hospital: ErrorResponseProps;
  addPoint: (point: PointInput) => Promise<void>;
}

interface PointsProviderProps {
  children: ReactNode;
}

export const PointsContext = createContext({} as PointsContextData);

export function DataProvider({ children }: PointsProviderProps) {
  const [points, setPoints] = useState<any>([]);
  const [errors, setErrors] = useState([]);

  const [loading, setLoading] = useState(true);
  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [nrDiasAnalise, setNrDiasAnalise] = useState(Number);

  const [hospital] = useState<ErrorResponseProps>({} as ErrorResponseProps);

  let pollingTimeOut;
  useEffect(() => {
    // setTimeout(getDataDash, 500);
    polling();

    return () => {
      clearTimeout(pollingTimeOut);
    };
  }, []);

  async function polling() {
    
    getDataDash();
    pollingTimeOut = setTimeout(() => polling(), 20000);
  }

 
  const getDataDash = async () => {
    await api
      .get<Point[]>(
        "/pontoIntegrSumario/buscarPorUltimoIntegradoMultiempresa",
        {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setPoints([]);
        if (response.data.length === 0) {
          setLoading(true);
        }
        setLoading(false);
        sessionStorage.setItem("snDetalheSucesso", response.data[0].empresaPontoIntegracao.snDetalheSucesso);
        sessionStorage.setItem("snDetalheAguardando", response.data[0].empresaPontoIntegracao.snDetalheAguardando);
        setNomeEmpresa(response.data[0].nomeEmpresa);
        const points = response.data;

        setPoints([{points}]);


        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].nrDiasAnalise != null) {
            setNrDiasAnalise(response.data[i].nrDiasAnalise);
          }
        }
      }
      )
      .catch(function (err) {
      });
  };


  async function addPoint(pointInput: PointInput) {
    const response = await api.post("/pontoIntegrSumario/inserir", {
      ...pointInput,
    });
  }

  return (
    <PointsContext.Provider
      value={{
        points,
        errors,
        loading,
        hospital,
        addPoint,
        nomeEmpresa,
        nrDiasAnalise,
      }}
    >
      {children}
    </PointsContext.Provider>
  );
}

export function usePoints() {
  const context = useContext(PointsContext);

  return context;
}