import { createContext, ReactNode, useState } from "react";
import { ErrorsDetailController } from "../Controllers/ErrorDetailsController/ErrorDetailsController";

interface ErrorsContextData {
  getDetails: () => void;
  errors: any;
  setErrors: any;
  nrDiasAnalise: any;
  integrationPoint: any;
  loadingDetails: boolean;
  setLoadingDetails: any;
  setFiltros: any;
  filtros: any;
  totalRegistros: any;
  setTotalRegistros: any;
  filiaisState: any;
  tpStatusErro: any;
  tpStatusAguardando: any;
  tpStatusSucesso: any;
  tpStatusOculto: any;
  EmpresaPontontegracao: any;
}

interface ErrorsProviderProps {
  children: ReactNode;
}

export const ErrorsContext = createContext({} as ErrorsContextData);

export function DataErrorsProvider({ children }: ErrorsProviderProps) {
  const filtrosEstadoInicial = {
    dataInicio: "",
    dataFim: "",
    status: "",
    dsStatus: "",
    filial: "",
    dsFilial: "",
    idRegistro: "",
  };

  const errorsDetailController = new ErrorsDetailController();
  const [page, setPage] = useState(1);
  const [qtResult, setQtResult] = useState(30);
  const [errors, setErrors] = useState([]);
  const [nrDiasAnalise, setNrDiasAnalise] = useState();
  const [integrationPoint, setIntegrationPoint] = useState([]);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [filtros, setFiltros] = useState(filtrosEstadoInicial);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [filiaisState, setFiliais] = useState([]);
  const [tpStatusErro, setTpStatusErro] = useState("");
  const [tpStatusAguardando, setTpStatusAguardando] = useState("");
  const [tpStatusSucesso, setTpStatusSucesso] = useState("");
  const [tpStatusOculto, setTpStatusOculto] = useState("");
  const [EmpresaPontontegracao, setEmpresaPontontegracao] = useState<any>();

  async function getDetails() {
    setLoadingDetails(true);
    await errorsDetailController
      .getList(page, qtResult, filtros)
      .then((response) => {
        setLoadingDetails(false);
        setErrors(response.data.empresaDetalheDTOAdapterList);
        setIntegrationPoint(response.data.empresaPontoIntegracaoDTO.nmNome);
        setEmpresaPontontegracao(response.data.empresaPontoIntegracaoDTO);

        setTpStatusErro(response.data.empresaPontoIntegracaoDTO.tpStatusErro);
        setTpStatusAguardando(
          response.data.empresaPontoIntegracaoDTO.tpStatusAguardando
        );
        setTpStatusSucesso(
          response.data.empresaPontoIntegracaoDTO.tpStatusSucesso
        );
        setTpStatusOculto(
          response.data.empresaPontoIntegracaoDTO.tpStatusOculto
        );

        if (response.data.empresaDetalheDTOAdapterList.length === 0) {
          setTotalRegistros(0);
        } else {
          setTotalRegistros(
            response.data.empresaDetalheDTOAdapterList[0].detalheDTOAdapter[0]
              .totalResultados
          );
        }


        setFiliais(response.data.pontoEmpresaFilialDTOList);

      })
      .catch((error) => {
        setFiltros(filtrosEstadoInicial);
        setLoadingDetails(false);
      });
  }

  return (
    <ErrorsContext.Provider
      value={{
        getDetails,
        errors,
        nrDiasAnalise,
        integrationPoint,
        loadingDetails,
        setLoadingDetails,
        setFiltros,
        filtros,
        totalRegistros,
        setTotalRegistros,
        setErrors,
        filiaisState,
        tpStatusErro,
        tpStatusAguardando,
        tpStatusSucesso,
        tpStatusOculto,
        EmpresaPontontegracao,
      }}
    >
      {children}
    </ErrorsContext.Provider>
  );
}
