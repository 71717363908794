import { Dispatch, ReactNode, createContext, useState } from "react";
import { getInfosChart } from "../Controllers/MetricsController/MetricsController";

interface GlobalContextProps {
  snNovaInterface: string;
  setSnNovaInterface: Dispatch<string>;
  handleGetChartData: () => void;
  dadosGrafico: any;
  showLoading: boolean;
  loadCallMetrics: boolean;
  callMetrics: () => void;
}

export const GlobalContext = createContext({} as GlobalContextProps);

interface GlobalProviderProps {
  children: ReactNode;
}
export function GlobalProvider({ children }: GlobalProviderProps) {
  const [snNovaInterface, setSnNovaInterface] = useState("");
  const [dadosGrafico, setDadosGrafico] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [loadCallMetrics, setLoadCallMetrics] = useState<boolean>(false);

  async function handleGetChartData() {
    // if (!loadCallMetrics) {
    setShowLoading(true);
    // }
    try {
      const { data } = await getInfosChart();
      setDadosGrafico(data);

      setShowLoading(false);
      setLoadCallMetrics(false);
    } catch (err) {
      setShowLoading(false);
      setLoadCallMetrics(false);
    }
  }

  async function callMetrics() {
    setLoadCallMetrics(true);

    await handleGetChartData();
  }
  return (
    <GlobalContext.Provider
      value={{
        snNovaInterface,
        setSnNovaInterface,
        handleGetChartData,
        dadosGrafico,
        showLoading,
        loadCallMetrics,
        callMetrics,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}
