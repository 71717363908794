import { api } from "../../services/api";

export class ErrorsDetailController {
  async getList(pagina: number, quantRetorno: number, filtros: any) {
    const response = api.get("/pontoIntegracaoDetalhe/buscarEmpresaDetalhe", {
      params: {
        pagina: pagina - 1,
        quantRetorno: quantRetorno,
      },
      headers: {
        token: sessionStorage.getItem("token"),
        cdEmpresaPontoIntegracao: sessionStorage.getItem(
          "cdEmpresaPontoIntegracao"
        ),
        dataInicio: filtros.dataInicio,
        dataFim: filtros.dataFim,
        status: filtros.status,
        filial: filtros.filial == null ? "" : filtros.filial,
        idRegistro: filtros.idRegistro,
      },
    });
    return response;
  }

  async reprocessar(cdIntegracao: any) {
    const response = api.post(
      `/empPontoIntegracao/reprocessarIntegracao`,
      {},
      {
        headers: {
          token: sessionStorage.getItem("token"),
          cdEmpresaPontoIntegracao: sessionStorage.getItem(
            "cdEmpresaPontoIntegracao"
          ),
          cdIntegracao: cdIntegracao,
        },
      }
    );
    return response;
  }

  async ocultar(cdIntegracao: any) {
    const response = api.post(
      `/empPontoIntegracao/ocultarRegistroIntegracao`,
      {},
      {
        headers: {
          token: sessionStorage.getItem("token"),
          cdEmpresaPontoIntegracao: sessionStorage.getItem(
            "cdEmpresaPontoIntegracao"
          ),
          cdIntegracao: cdIntegracao,
        },
      }
    );
    return response;
  }

  async regerar(cdIntegracao: any) {
    const response = api.post(
      `/empPontoIntegracao/regerarDados`,
      {},
      {
        headers: {
          token: sessionStorage.getItem("token"),
          cdEmpresaPontoIntegracao: sessionStorage.getItem(
            "cdEmpresaPontoIntegracao"
          ),
          cdIntegracao: cdIntegracao,
        },
      }
    );
    return response;
  }
}
