import { api } from "../../services/api"

export class ErrorDetailsAntigoController{

    async getErrorDetails(){
        const response = api.get(`/pontoIntegracaoDetalhe/buscarEmpresaDetalheErro`, {
            headers: {
                token: sessionStorage.getItem("token"),
                cdEmpresaPontoIntegracao: sessionStorage.getItem("cdEmpresaPontoIntegracao"),
            }

        })
        return response
    }
    
    async getAwaitDetails(){
        const response = api.get(`/pontoIntegracaoDetalhe/buscarEmpresaDetalheAguardando`, {
            headers: {
                token: sessionStorage.getItem("token"),
                cdEmpresaPontoIntegracao: sessionStorage.getItem("cdEmpresaPontoIntegracao"),
            }
        })
        return response;
    }

    async getSucessDetails(){
        const response = api.get(`/pontoIntegracaoDetalhe/buscarEmpresaDetalheSucesso`, {
            headers: {
                token: sessionStorage.getItem("token"),
                cdEmpresaPontoIntegracao: sessionStorage.getItem("cdEmpresaPontoIntegracao"),
            }
        })
        return response;
    }
}