import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface Req {
  arrayIntegraDia;
}

export function BarChart(props: Req) {
  var valueDay = props.arrayIntegraDia.map(function (item, indice) {
    return item.valor;
  });

  const labels = props.arrayIntegraDia.map(function (item, indice) {
    return item.descricao;
  });

  const options = {
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Dias",
          color: "#171717",
          font: {
            size: 12,
            weight: "normal",
            lineHeight: 1.2,
          },
          padding: { top: 20, left: 0, right: 0, bottom: 0 },
        },
      },
      y: {
        type: "linear" as const,
        display: true,
        position: "left" as const,
        title: {
          display: true,
          text: "Quantidade",
          color: "#171717",

          padding: { top: 0, left: 0, right: 0, bottom: 10 },
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        data: valueDay,
        backgroundColor: "hsl(133, 67%, 65%)",
        yAxisID: "y",
      },
    ],
  };

  return (
    <>
      <Bar
        options={options}
        data={data}
        style={{
          maxHeight: "200px",
          width: "1270px",
        }}
        className="line"
      />
    </>
  );
}
