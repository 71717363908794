import { Chart } from "../../components/Chart";
import { Header } from "../../components/Header";
import { SideBar } from "../../components/SideBar";

import "./styles.scss";

export function Metrics() {
  return (
    <>
      <SideBar />
      <section>
        <Header dayValue={30} showAtualizacao={false} showLogo />
        <div className="chart-container">
          <Chart />
        </div>
      </section>
    </>
  );
}
