import { ReactNode, createContext, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useApi } from "../../services/api";
import { User } from "../../types/User";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export interface TelasCustom {
  cdTelaCustomPessoa: number;
  cdTelaCustom: number;
  dsTelaCustom: string;
  cdPessoa: number;
  dtHoraCriado: string;
  dtHoraAlterado: string;
}

export type AuthContextType = {
  user: User | null;
  UserLogin: (email: string, password: string) => Promise<boolean>;
  telasCustom: TelasCustom[];
  signout: () => void;
};

export const AuthContext = createContext({} as AuthContextType);

interface AuthProviderProps {
  children: ReactNode;
}
export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [telasCustom, setTelasCustom] = useState<TelasCustom[]>([]);
  const api = useApi();

  console.log("telasCustom", telasCustom);
  useEffect(() => {
    const validateToken = async () => {
      const token = sessionStorage.getItem("token");
      const user = sessionStorage.getItem("nmNome");
      const tipoPessoa = sessionStorage.getItem("tipoPessoa");

      if (token) {
        setUser(user as User);
      }
    };
    validateToken();
  }, []);

  const UserLogin = async (
    email: string,
    password: string,
    logado?: boolean,
    tipoPessoa?: string,
    token?: string
  ) => {
    await api
      .UserLogin(email, password)
      .then((res) => {
        setUser(res.data.dsLogin);
        setUser(res.data.nmNome);

        tipoPessoa = res.data.tipoPessoa.cdTipoPessoa;
        token = res.data.token;

        sessionStorage.setItem("snNovaInterface", res.data.snNovaInterface);
        userStorage(res.data.nmNome);
        setToken(token);
        setTipoPessoa(tipoPessoa);
        setTelasCustom(res.data.telasCustom);
        logado = true;
      })
      .catch((err) => {
        const { data } = err.response;
        toast.error(data.message);

        logado = false;
      });

    return logado;
  };
  const signout = async () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("nmNome");
    setUser(null);
  };

  const userStorage = (user: string) => {
    sessionStorage.setItem("nmNome", user);
  };
  const setToken = (token: string) => {
    sessionStorage.setItem("token", token);
  };

  const setTipoPessoa = (tipoPessoa: string) => {
    sessionStorage.setItem("tipoPessoa", tipoPessoa);
  };

  return (
    <AuthContext.Provider value={{ user, UserLogin, signout, telasCustom }}>
      {children}
    </AuthContext.Provider>
  );
};
