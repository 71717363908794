import { Header } from "../Header";
import "./styles.scss";
export default function NewTable() {
  return (
    <div className="newTable-container">
      <Header message={"Dashboard/telas"} showAtualizacao={true} />
      <main>
        <div className="newTable-main-card">
          <header className="newTable-main-card-header">header</header>
          <section className="newTable-main-card-section">main</section>
        </div>
      </main>
    </div>
  );
}
