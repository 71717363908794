import "./styleModalConfirmacoes.scss";

interface ModalConfirmacaoProps {
  id: number;
  title: string;
  reprocessar: any;
  ocultar: any;
  setFlagModal: any;
  regerar: any;
}

export function ModalConfirmacoes(props: ModalConfirmacaoProps) {
  function closeModal(e: any) {
    if (e.target === e.currentTarget) {
      props.setFlagModal(false);
    }
  }

  function acoes() {
    if (props.title === "reprocessar") {
      props.reprocessar(props.id);
    } else if (props.title === "ocultar") {
      props.ocultar(props.id);
    } else if (props.title === "regerar") {
      props.regerar(props.id);
    }
  }

  return (
    <div className="modalConfirmacao-background" onClick={(e) => closeModal(e)}>
      <div className="modalConfirmacao-content">
        <div className="modalConfirmacao-content-title">
          <p>
            Deseja {props.title} o id: {props.id} ?
          </p>
        </div>
        <div className="modalConfirmacao-content-buttons">
          <button
            className="confirm-button"
            onClick={() => (acoes(), props.setFlagModal(false))}
            // style={{ backgroundColor: "#da8639" }}
          >
            Sim
          </button>
          <button
            className="cancel-button"
            onClick={() => props.setFlagModal(false)}
            // style={{ backgroundColor: "#9e9e9e" }}
          >
            Não
          </button>
        </div>
      </div>
    </div>
  );
}
