import { IoClose } from "react-icons/io5";
import "./styleModalDetalhes.scss";

interface modalDetalhesProps {
  text: string;
  title: string;
  setFlagModal: any;
}

export function ModalDetalhes(props: modalDetalhesProps) {
  function closeModal(e: any) {
    if (e.target === e.currentTarget) {
      props.setFlagModal(false);
    }
  }

  //preciso que o props. text que que é uma string seja quebrado em um array sempre que tiver um ;

  const textArray = props.text.split(";");

  return (
    <div className="modalDetalhes-background" onClick={(e) => closeModal(e)}>
      <div className="modalDetalhes-content">
        <div className="close-div">
          <button onClick={() => props.setFlagModal(false)}>
            <IoClose size={25} color="#1871ca" />
          </button>
        </div>
        <div className="modalDetalhes-titulo">
          <h3 className="tituloModalDetalhes">{props.title}</h3>
        </div>
        <div className="modalDetalhes-texto">
          <ul>
            {textArray.map((text, index) => (
              <li key={index}>{text}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}