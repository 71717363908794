import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";

export const useCalendar = () => {
  const {
    dadosGrafico,
    showLoading,
    callMetrics,
    loadCallMetrics,
    handleGetChartData,
  } = useContext(GlobalContext);

  useEffect(() => {
    if (dadosGrafico.length === 0) {
      handleGetChartData();
    }
  }, [dadosGrafico.length, handleGetChartData]);
  //variáveis do tipo useState para controle da aplicação;
  const [showCalendar, setShowCalendar] = useState(false);
  const [showCalendarMonth, setShowCalendarMonth] = useState(false);
  const [showCalendarYear, setShowCalendarYear] = useState(false);
  const [showCalendarMultiDates, setShowCalendarMultiDates] = useState(false);

  const handleViewFullYear = (date) => {
    const year = date.getFullYear(); // pega o ano da data selecionada;
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // pega e formata o mês da data selecionada;
    const day = ("0" + date.getDate()).slice(-2); // pega e formata o dia da data selecionada;

    const result = `${day}/${month}/${year}`; //Formata a data para o formato dd/mm/yyyy

    sessionStorage.setItem("day", result); // salvar o ano selecionado no sessionStorage

    setShowCalendarMonth(false); // fechar o calendário quando uma data é selecionada
  };
  async function handleViewMonth(data) {
    const year = data.activeStartDate.getFullYear(); // pega o ano da data selecionada;
    const month = ("0" + (data.activeStartDate.getMonth() + 1)).slice(-2); // pega e formata o mês da data selecionada;
    const day = ("0" + data.activeStartDate.getDate()).slice(-2); // pega e formata o dia da data selecionada;

    const result = `${day}/${month}/${year}`; //Formata a data para o formato dd/mm/yyyy
    sessionStorage.setItem("month", result); // salvar o ano selecionado no sessionStorage
    setShowCalendar(false); // fechar o calendário quando uma data é selecionada
  }

  const MultipleDates = (dates) => {
    const valor1 = dates[0]; // pegar a primeira data selecionada do array;
    const valor2 = dates[1]; // pegar a segunda data selecionada do array;

    const day1 = ("0" + valor1.getDate()).slice(-2); // pega e formata o dia da data selecionada;
    const day2 = ("0" + valor2.getDate()).slice(-2); // pega e formata o dia da data selecionada;

    const month1 = ("0" + (valor1.getMonth() + 1)).slice(-2); // pega e formata o mês da data selecionada;
    const month2 = ("0" + (valor2.getMonth() + 1)).slice(-2); // pega e formata o mês da data selecionada;

    const year1 = valor1.getFullYear(); // pega e formata o ano da data selecionada;
    const year2 = valor2.getFullYear(); // pega e formata o ano da data selecionada;

    const result1 = `${day1}/${month1}/${year1}`; //Formata a data para o formato dd/mm/yyyy;
    const result2 = `${day2}/${month2}/${year2}`; //Formata a data para o formato dd/mm/yyyy;

    sessionStorage.setItem("day1", result1); // salvar o ano selecionado no sessionStorage
    sessionStorage.setItem("day2", result2); // salvar o ano selecionado no sessionStorage

    setShowCalendarMultiDates(false); // fechar o calendário quando uma data é selecionada
  };
  const handleViewYear = (year) => {
    const ano = year.activeStartDate.getFullYear(); // pega o ano da data selecionada;
    sessionStorage.setItem("year", ano); // salvar o ano selecionado no sessionStorage
    setShowCalendarYear(false); // fechar o calendário quando uma data é selecionada
  };

  //Variáveis para pegar os valores do sessionStorage e exibir no gráfico
  let monthChart = sessionStorage.getItem("month");
  let yearChart = sessionStorage.getItem("year");

  return {
    handleViewFullYear,
    handleViewYear,
    MultipleDates,
    handleViewMonth,
    callMetrics,
    loadCallMetrics,
    showLoading,
    showCalendar,
    showCalendarMonth,
    showCalendarYear,
    showCalendarMultiDates,
    monthChart,
    yearChart,
    setShowCalendar,
    dadosGrafico,
    setShowCalendarYear,
    setShowCalendarMonth,
    setShowCalendarMultiDates,
  };
};
