import { useContext } from "react";
import { AiOutlinePieChart } from "react-icons/ai";
import "./styles.scss";

import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { MetricsContext } from "../../contexts/MetricsContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement
);

export function PieChartDashboard1() {
  const { points } = useContext(MetricsContext);

  var valor = [];
  var nmNome = [];
  var valorSucesso = [];
  var valorErro = [];
  var descricao = ["Sucesso", "Erro"];
  var cores = ["hsl(133, 67%, 65%)", "hsl(0, 68%, 65%)"];
  var totalErro = 0;
  var totalSucesso = 0;

  valorErro = points?.map((item) => item.nrErro) || [];
  valorSucesso = points?.map((item) => item.nrSucesso) || [];
  nmNome = points?.map((item) => item.empresaPontoIntegracao.nmNome) || [];

  for (var i = 0; i < valorErro.length; i++) {
    totalErro += valorErro[i];
  }

  for (var i = 0; i < valorSucesso.length; i++) {
    totalSucesso += valorSucesso[i];
  }

  valor = [totalSucesso, totalErro];

  //variaveis de controle dos arrays abaixo
  var valorTotalSucesso = 0;
  var valorTotalErro = 0;
  var resultadoSucesso = [];
  var resultadoErro = [];

  //percore o array de sucessos e calcula a porcentagem de cada valor

  for (var i = 0; i < nmNome.length; i++) {
    var porcentagens = []; // array para armazenar todas as porcentagens
    for (var j = 0; j < valorSucesso.length; j++) {
      // renomeia a variável do segundo loop para evitar sobreposição
      valorTotalSucesso = valorErro[j] + valorSucesso[j]; // calcula o valor total
      var porcentagem = (valorSucesso[j] / valorTotalSucesso) * 100;

      // adiciona a porcentagem ao array de porcentagens
      porcentagens.push(porcentagem.toFixed(2));
    }

    resultadoSucesso.push(
      `${descricao[0]}: ${nmNome[i]} (${porcentagens[i]}%); \n`
    );
  }
  var resultadoFinal = resultadoSucesso.join(""); // Unir todos os resultados em uma única string

  for (var i = 0; i < nmNome.length; i++) {
    var porcentagens2 = []; // array para armazenar todas as porcentagens
    for (var j = 0; j < valorErro.length; j++) {
      // renomeia a variável do segundo loop para evitar sobreposição
      valorTotalErro = valorErro[j] + valorSucesso[j]; // calcula o valor total
      var porcentagem2 = (valorErro[j] / valorTotalErro) * 100; // calcula a porcentagem usando o valor total

      porcentagens2.push(porcentagem2.toFixed(2));
    }

    resultadoErro.push(
      `${descricao[1]}: ${nmNome[i]} (${porcentagens2[i]}%); \n`
    );
  }
  var resultadoFinal2 = resultadoErro.join(""); // Unir todos os resultados em uma única string
  //junta os dois arrays em um único array para exibição no tooltip
  var arrayFinal = [resultadoFinal, resultadoFinal2];

  const dataPie = {
    datasets: [
      {
        data: valor,
        backgroundColor: cores,
        borderColor: cores,
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div className="text">
        <p>Gráfico Total</p>
        <i>
          <AiOutlinePieChart /> <p>{valor.length}</p>
        </i>
      </div>
      <div className="pie">
        <div>
          <Pie
            data={dataPie}
            options={{
              plugins: {
                tooltip: {
                  enabled: true,
                  mode: "nearest",
                  callbacks: {
                    label: (tooltipItem) => {
                      // retorna a descrição e o valor correspondente ao tooltipItem
                      const label = arrayFinal;
                      return `${label[tooltipItem.dataIndex]}`
                        .replaceAll(",", "")
                        .split("\n");
                    },
                  },
                },
              },
            }}
            style={{ maxHeight: "150px" }}
          />
        </div>
      </div>

      <div className="pie">
        <div className="infos-pie">
          {descricao.map((item, index) => (
            <h6 key={index}>
              <span style={{ backgroundColor: cores[index] }}>.</span>
              {item}
            </h6>
          ))}
        </div>
      </div>
    </>
  );
}
