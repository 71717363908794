import axios from "axios";
import MD5 from "crypto-js/md5";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const useApi = () => ({
  validateToken: async (token: string) => { },
  UserLogin: async (email: string, password: string) => {
    const response = await api.post(
      "/pessoa/login",
      {},
      {
        headers: {
          dsEmail: email,
          dsSenha: MD5(password),
        },
      }
    );

    return response;
  },
  logout: async () => {
    const response = await api.post("/pessoa/logout");
    return response.data;
  },
});
