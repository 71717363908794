import Button from "@mui/material/Button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@radix-ui/react-popover";
import { BiLineChart } from "react-icons/bi";
import { BarChart } from "../BarChart";
import { BarChart2 } from "../BarChart2";
import { BarChart3 } from "../BarChart3";
import { PieChartDashboard1 } from "../DashboardPieCharts/pieChart1";
import { PieChart } from "../PieChart";
import { PieChart1 } from "../PieChart1";
import { PieChart2 } from "../PieChart2";
import { CalendarBase } from "./calendarComponent";
import "./styles.scss";

import { Backdrop, CircularProgress } from "@mui/material";
import Carousel from "nuka-carousel";
import "react-calendar/dist/Calendar.css";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import { useCalendar } from "./useCalendar";

export function Chart() {
  const {
    MultipleDates,
    handleViewFullYear,
    handleViewMonth,
    handleViewYear,
    callMetrics,
    loadCallMetrics,
    monthChart,
    showCalendar,
    showCalendarMonth,
    showCalendarMultiDates,
    showCalendarYear,
    showLoading,
    yearChart,
    dadosGrafico,
    setShowCalendar,
    setShowCalendarMonth,
    setShowCalendarYear,
    setShowCalendarMultiDates,
  } = useCalendar();
  const CustomButton = ({ onClick, icon }) => {
    return <Button onClick={onClick}>{icon}</Button>;
  };

  console.log(showLoading);

  return (
    <div className="chart-content">
      <h4 className="button-refresh">
        <Button variant="outlined" onClick={() => callMetrics()}>
          Atualizar
          {loadCallMetrics && <span className="loading-icon"></span>}
        </Button>
      </h4>

      {!loadCallMetrics && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Carousel
        autoplay={true}
        autoplayInterval={16000}
        wrapAround={true}
        style={{ width: "1160px" }}
        renderCenterLeftControls={({ previousSlide }) => (
          <CustomButton
            onClick={previousSlide}
            icon={<FaArrowAltCircleLeft />}
          />
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <CustomButton onClick={nextSlide} icon={<FaArrowAltCircleRight />} />
        )}
      >
        {dadosGrafico.map((item) => (
          <div className="chart-content">
            <h4 className="bank-name">{item.nomeBanco}</h4>
            <div className="details">
              <div className="chart-line bg1">
                <div className="div-calendar">
                  {showCalendar && (
                    <Popover>
                      <PopoverTrigger className="calendar-popover">
                        <CalendarBase
                          onViewChange={handleViewMonth}
                          values={handleViewMonth}
                          options={true}
                          maxDate={false}
                          showNavigation={true}
                          view="year"
                          hideExtraYears={false}
                          selectRange={false}
                        />
                      </PopoverTrigger>
                      <PopoverContent className="calendar-popover"></PopoverContent>
                    </Popover>
                  )}
                </div>
                <p className="p-chart-one">
                  <button
                    onClick={() => setShowCalendar(true)}
                    className="button-calendar"
                  >
                    📅
                  </button>
                  <span className="title-span">
                    <BiLineChart />
                    <p className="title-p">
                      Integraçôes por mês: {monthChart.substring(3, 10)}
                    </p>
                  </span>
                </p>
                <BarChart arrayIntegraDia={item.graficoDTOIntegrDiaList} />
              </div>
            </div>
            <div className="details">
              <div className="chart-line bg">
                {showCalendarYear && (
                  <Popover>
                    <PopoverTrigger className="calendar-popover">
                      <CalendarBase
                        onViewChange={handleViewYear}
                        values={handleViewYear}
                        options={true}
                        showNavigation={true}
                        maxDate={false}
                        view="decade"
                        hideExtraYears={true}
                        selectRange={false}
                      />
                    </PopoverTrigger>

                    <PopoverContent className="calendar-popover"></PopoverContent>
                  </Popover>
                )}
                <p className="p-chart-two">
                  <button
                    onClick={() => setShowCalendarYear(true)}
                    className="button-calendar"
                  >
                    📅
                  </button>
                  <span className="title-span">
                    <BiLineChart />
                    <p className="title-p">Integrações por ano: {yearChart}</p>
                  </span>
                </p>
                <BarChart3 arrayIntegraMes={item.graficoDTOIntegrMesList} />
              </div>
              <div className="chart-line bg">
                <div className="div-calendar">
                  {showCalendarMonth && (
                    <Popover>
                      <PopoverTrigger className="calendar-popover">
                        <CalendarBase
                          values={handleViewFullYear}
                          onViewChange={handleViewFullYear}
                          options={false}
                          showNavigation={true}
                          view="month"
                          maxDate={false}
                          hideExtraYears={false}
                          selectRange={false}
                        />
                      </PopoverTrigger>

                      <PopoverContent></PopoverContent>
                    </Popover>
                  )}
                </div>
                <p className="p-chart-two">
                  <button
                    onClick={() => setShowCalendarMonth(true)}
                    className="button-calendar"
                  >
                    📅
                  </button>
                  <span className="title-span">
                    <BiLineChart />
                    <p className="title-p">
                      Integrações por dia:
                      {/* {dayChart} */}
                    </p>
                  </span>
                </p>
                <BarChart2 arrayIntegraHora={item.graficoDTOIntegrHoraList} />
              </div>

              {/*Gráfico de Cliente por Integração*/}
              <div className="details">
                <div className="records bg">
                  <div className="div-calendar">
                    {showCalendarMultiDates && (
                      <Popover>
                        <PopoverTrigger className="calendar-popover">
                          <CalendarBase
                            values={MultipleDates}
                            onViewChange={MultipleDates}
                            options={true}
                            showNavigation={true}
                            view="defaultView"
                            maxDate={true}
                            hideExtraYears={false}
                            selectRange={true}
                          />
                        </PopoverTrigger>

                        <PopoverContent></PopoverContent>
                      </Popover>
                    )}
                  </div>
                  <p className="p-chart-two">
                    <button onClick={() => setShowCalendarMultiDates(true)}>
                      📅
                    </button>
                    <span className="title-span">
                      <BiLineChart />
                      <p className="title-p">
                        Integrações por ponto:
                        {/* {day1Chart} - {day2Chart} */}
                      </p>
                    </span>
                  </p>
                  <PieChart
                    arrayPontoIntegracao={item.graficoDTOPontoIntegracaoList}
                  />
                </div>
              </div>
            </div>
            <div className="details">
              <div className="records bg">
                {/*Gráfico de Cliente por Fluxo*/}
                <PieChart1 arrayGraficoFluxo={item.graficoDTOFluxoList} />
              </div>

              {/*Gráfico de Cliente por Arquitetura*/}
              <div className="records bg">
                <PieChart2
                  arrayGraficoArquitetura={item.graficoDTOArquiteturaList}
                />
              </div>

              <div className="records bg">
                <PieChartDashboard1 />
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
