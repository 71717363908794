import { createContext, ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ErrorDetailsAntigoController } from "../../Controllers/ErrorDetailsAntigo/ErrorDetailsAntigo";

interface ErrorsContextData {
  errors: any;
  setErrors: any;
  nrDiasAnalise: any;
  integrationPoint: any;
  loadingDetails: boolean;
  setLoadingDetails: any;
}

interface ErrorsProviderProps {
  children: ReactNode;

}

export const ErrorsContextAntigo = createContext({} as ErrorsContextData);

export function DataErrorsProviderAntigo({ children }: ErrorsProviderProps) {

  const errorsDetailController = new ErrorDetailsAntigoController();
  const [errors, setErrors] = useState([]);
  const [nrDiasAnalise, setNrDiasAnalise] = useState();
  const [integrationPoint, setIntegrationPoint] = useState([]);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const {tpStatus} = useParams();
  

  useEffect(() => {
    setLoadingDetails(true)
    switch (tpStatus) {
      case 'Aguardando':
        getAwait();
        break;
      case 'Sucesso':
        getSucess();
        break;
      case 'Erro':
        getErrors();
        break;
    }
  }, [])

  async function getErrors(){
    errorsDetailController.getErrorDetails()
    .then((response) =>{
      setLoadingDetails(false);
      setErrors(response.data.empresaDetalhamentoDTOAdapterList);
      setIntegrationPoint(response.data.empresaPontoIntegracaoDTO.nmNome);
    }).catch((error) =>{
      setLoadingDetails(false);
      toast.error("Erro ao buscar dados");
    })
  }

  async function getAwait(){
    errorsDetailController.getAwaitDetails()
    .then((response) => {
      setLoadingDetails(false);
      setErrors(response.data.empresaDetalhamentoDTOAdapterList);
      setIntegrationPoint(response.data.empresaPontoIntegracaoDTO.nmNome);
    }).catch((error) =>{
      setLoadingDetails(false);
      toast.error("Erro ao buscar dados");
    })
  }

  async function getSucess(){
    errorsDetailController.getSucessDetails()
    .then((response) => {
      setLoadingDetails(false);
      setErrors(response.data.empresaDetalhamentoDTOAdapterList);
      setIntegrationPoint(response.data.empresaPontoIntegracaoDTO.nmNome);
    }).catch((error) => {
      setLoadingDetails(false);
      toast.error("Erro ao buscar dados");
    })
  }


  return (
    <ErrorsContextAntigo.Provider
      value={{
        errors,
        nrDiasAnalise,
        integrationPoint,
        loadingDetails,
        setLoadingDetails,
        setErrors,
      }}
    >
      {children}
    </ErrorsContextAntigo.Provider>
  );
}
